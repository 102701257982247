<template>
<div class="head-box">
  <div class="head-center-box">
    <div class="head-content">
      <v-img
          class="img"
          :attachment-id="val.img"
          :default-img="require('@/assets/img/solution/kctp.png')"
      />
      <div class="content-text">

          <div>
            <p>{{val.name}}</p>
            <a-button  type="danger" class="btn-red"  @click="consult">
              国产替代咨询
            </a-button>

          </div>

          <div>
            <div v-for="(item,index) in val.tag" :key="index">{{item}}</div>
          </div>

          <div>
            <p>{{val.text}}</p>
          </div>

          <div>
            <div class="info left" v-if="val.type==5"> <p>
              <img class="info-icon" :src="iconImg[4].img" alt="">
              发布者：</p> <p>{{val.by_name}}</p></div>

            <div class="info right" v-if="val.type==5"> <p>
              <img class="info-icon" :src="iconImg[3].img" alt="">
              发布时间：</p> <p>{{val.by_time}}</p></div>

            <div class="share">
              <p>点击分享：</p>

              <div >
                <a-popover placement="bottom" @mouseenter="showCode()">
                  <div slot="content" class="wx-code-div">
                    <div id="qrcode" class="qrcode" ref="qrCodeUrl"  style="width: 140px;height: 140px">
                    </div>
                  </div>
                  <img alt="图片" type="primary" src="@/assets/img/weixin.png" />
                </a-popover>
              </div>

              <div>
                <img src="@/assets/img/activity/weibo.png" alt="图片"  @click="shareToMicroblog()">
              </div>

              <div>
                <img src="@/assets/img/activity/kongjian.png" alt="图片" @click="shareToKj()">
              </div>

              <div>
                <img src="@/assets/img/activity/QQ.png" alt="图片"  @click="shareToQQ()">
              </div>

            </div>

          </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import QRCode from "qrcodejs2";

let url = document.location; //获取当前网页地址
import VImg from "@/components/VImg";
import {mapGetters} from "vuex";
import {getH5path} from "@/api/common"

export default {
  name: "program_head",
  props:['val'],
  computed: {
    ...mapGetters(["isLogin"]),
  },
  components:{
    VImg,
  },
  data(){
    return{
      wxUrl: "",
      h5path: "",
      resultInfo:'',
      other_img:{
        img1:require("@/assets/img/solution/program1.png")
      },
      iconImg:[
        {
          img:require("@/assets/img/solution/program2.png")
        },
        {
          img:require("@/assets/img/solution/program3.png")
        },
        {
          img:require("@/assets/img/solution/program4.png")
        },
        {
          img:require("@/assets/img/solution/program10.png")
        },
        {
          img:require("@/assets/img/solution/program11.png")
        }
      ]
    }
  },
  mounted() {
    this.wxUrl = ""
    this.h5path = "";
    this.getWxH5path();
  },
  methods:{


    consult() {

      if (!this.isLogin) {
        this.$message.warning("您还未登录");
        return;
      }
      this.$router.push({
        name: "userScenesAdd",
        params: {
          type: "add",
          info: "",
        },
      });
    },


    shareToMicroblog() {
      var sharesinastring =
          "http://service.weibo.com/share/share.php?title=" +
          encodeURIComponent(
              "「" + this.val.name + "」" + " 点这里" + url
          );
      window.open(sharesinastring, "_blank");
    },

    shareToQQ() {
      var _shareUrl = "https://connect.qq.com/widget/shareqq/index.html?";
      _shareUrl += "url=" + encodeURIComponent(url);
      _shareUrl += "&sharesource=qzone";
      _shareUrl += "&title=" + encodeURIComponent(this.val.name);
      window.open(_shareUrl, "_blank");
    },

    shareToKj() {
      let { val } = this;
      window.open(
          `https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${encodeURIComponent(
              url
          )}&sharesource=qzone&title=${val.name}`
      );
    },
    getWxH5path() {
      getH5path().then(res => {
        this.h5path = res.url
      });
    },
    showCode() {
      setTimeout(() => {
        this.shareToWeixin()
      }, 300)
    },
    shareToWeixin() {
      /**
       数字化转型
       /solution/ordinary_program?id=1549640469816913921
       */
      let id = this.$route.query.id
      if (id) {
        //课程
        this.wxUrl = this.h5path + "/solution/ordinary_program?id=" + id;
        this.$nextTick(() => {
          document.getElementById("qrcode").innerHTML = "";
          new QRCode(this.$refs.qrCodeUrl, {
            text: this.wxUrl, // 需要转换为二维码的内容
            width: 140,
            height: 140,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.H
          })
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
  .head-box{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    margin-bottom: 24px;
    .head-center-box{
      width: 1280px;
      height: 264px;
      .head-content{
        margin: 33px 0 42px 0;
        display: flex;
        justify-content: flex-start;
        .img{
          display: block;
          width: 334px;
          height: 198px;
          border: 1px solid #EEEEEE;
        }
        .content-text{
          width: 907px;
          height: 198px;
          margin-left: 36px;
           > :nth-child(1) {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            >p {
              font-size: 24px;
              color: #262626;
              line-height: 31px;
            }
            .ant-btn::v-deep{
              width: 140px;
              height: 40px;
              font-size: 16px;
            }
          }
           > :nth-child(2){
            display: flex;
            justify-content: flex-start;
            >div{
              background: #F6F6F6;
              border-radius: 3px;
              padding: 2.6px 6px 1px;
              margin-right: 8px;
              font-size: 12px;
              line-height: 17px;
              //font-family: PingFang SC;
              font-weight: 500;
              color: #666666;
            }

          }
           > :nth-child(3){
            margin-top: 17px;
             height: 70px;
            >p{
              font-size: 16px;
              //font-family: PingFang SC;
              font-weight: 500;
              line-height: 24px;
              color: #333333;
            }
          }

           > :nth-child(4){
             margin-top:4px;
              >.info{
                margin-top: 12px;
                font-size: 14px;
                float: left;
                font-weight: 500;
                line-height: 20px;
                color: #BFBFBF;
                .info-icon{
                  width: 20px;
                  height: 20px;
                  display: block;
                  margin-right: 2px;
                }
                >p{
                  float: left;
                  display: flex;
                  justify-content: flex-start;
                }
              }
              >.right{
                //width: 300px;
                margin-left: 18px;
              }
             > .share {
               float: right;
               display: flex;
               justify-content: flex-start;
               margin: 8px 0 5px 0;
               > p {
                 font-size: 14px;
                 line-height: 19px;
                 color: #333333;
               }

               > div {
                 line-height: 19px;
                 > img {
                   display: block;
                 }
               }

               > div:hover {
                 cursor: pointer;
               }

               > :nth-child(2) {
                 margin-left: 20px;
               }

               > :nth-child(3),
               > :nth-child(4),
               > :nth-child(5) {
                 margin-left: 12px;
               }
             }

          }



        }
      }
    }

  }
</style>
