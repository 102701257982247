<template>
<div class="program">
  <program-head :val="head_content" ></program-head>
  <carousel2   class="carousel" :val="img_content"></carousel2>
  <sub-bottom></sub-bottom>
</div>
</template>

<script>

import program_head from "./components/program_head"
import carousel2 from "./components/carousel2"
import { getSolutionInfo } from "@/api/solution";
import {mapGetters} from "vuex";
import sub_bottom from "@/page/solution/components/sub_bottom";


export default {
  name: "ordinary_program",
  data(){
    return{
        head_content:'',
        imglist:[],
        img_content:{
          imglist: [1,2,3],
          type: 1,
        },
    }
  },
  computed: {
    ...mapGetters(["isLogin"]),
    id: function () {
      return this.$route.query.id;
    },
  },
  components:{
    "program-head": program_head,
    carousel2,
    "sub-bottom":sub_bottom,
  },
  mounted() {
    this.loadGetSolutionInfo();
  },
  methods: {
    loadGetSolutionInfo() {
      getSolutionInfo(this.id).then((res) => {

        let taglist = res.tag.split(',').slice(0,2)

        let time=''

         if(res.hotType==5){
           time=res.publishedTime.substring(0,10)
         }


        this.head_content = {
          name: res.name,
          text: res.solutionDiscription,
          tag: taglist,
          by_name: res.publishedBy,
          by_time: time,
          img: res.solutionImg,
          type: res.hotType,
        }

        this.img_content = {
          imglist: res.imgList,
          type: res.hotType,
        }


      })
    },
  }
}
</script>

<style scoped lang="scss">
.program{
  display: flex;
  flex-direction:column;
  justify-content: flex-start;
  align-items: center;
}
.carousel{
  width: 1280px;
  //height: 1017px;
  margin-bottom: 51px;
}
</style>
